import React from 'react';
import { useState } from 'react'
import logo from './logo.svg';
import './App.css';
import { WebcamStreamCapture } from './camcorder/Camera'
import FullscreenModal from './camcorder/FullScreenModal'

const App: React.FC = () => {
  return (
    <div>
      <FullscreenModal/>
    <WebcamStreamCapture/>
    </div>
  );
};

export default App;
