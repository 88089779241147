import React from 'react';
import { useState } from 'react'
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const ModalButton = styled.button`
    display: block;
    position: fixed;
    z-index: 999;
    left: 50%;
    bottom: 20px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
`

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  position: relative;
  top: -50px;
  margin: 0;
  color: white;
`;

interface FullscreenModalProps {
//   isOpen: boolean;
//   onClose: () => void;
}

const FullscreenModal: React.FC<FullscreenModalProps> = () => {

  const [isModalOpen, setIsModalOpen] = useState(true);

  if (!isModalOpen) {
    return null;
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalOverlay onClick={handleModalClose}>
      <ModalContent>
        <ModalButton onClick={() => setIsModalOpen(true)}>Open Modal</ModalButton>
        <Title>Do the challenge on video to win a cash prize</Title>
        {/* Add your inner components here */}
      </ModalContent>
    </ModalOverlay>
  );
};

export default FullscreenModal;